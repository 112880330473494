.logoBuilderSlide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.logoBuilderSlideHeader {
  color: #0055a4;
  font-size: 57px;
  font-weight: 800;
  margin-bottom: 10px !important;
}
.logoBuilderSlideFooter {
  margin: 30px 0px !important;
}
.logoBuilderSlideFooterButton {
  background-color: #0055a4;
  color: #fff;
  padding: 15px 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
}
.logoBuilderSlideFooterButton:hover {
  background-color: rgba(0, 85, 164, 0.8);
}
.logoBuilderSlideContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 50px;
}

.svgItemContainer {
  display: flex;
  overflow: auto;
  overflow-y: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
  height: 560px;
  flex-direction: row;
  width: 23%;
  flex-wrap: wrap;
}
/* WebKit browsers */
.svgItemContainer::-webkit-scrollbar {
  width: 5px;
}

.svgItemContainer::-webkit-scrollbar-track {
  background: white;
}

.svgItemContainer::-webkit-scrollbar-thumb {
  background-color: #0055a4;
  border-radius: 10px;
}
.active {
  border: 4px solid #0055a4 !important;
}

.logoBuilderSlideSidebarItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #f2f2f2;
  padding: 7px;
  background-color: #fff;
  height: auto;
  width: 100%;
  margin: 3px 0;
  cursor: pointer;
}
.logoBuilderSlideSidebarItemContainer svg {
  height: auto;
}

.logoBuilderSlideTemplateViewContainer {
  display: flex;
  overflow: auto;
  overflow-y: scroll;
  height: 560px;
  flex-direction: row;
  width: 50%;
  flex-wrap: wrap;
}
/* WebKit browsers */
.logoBuilderSlideTemplateViewContainer::-webkit-scrollbar {
  width: 5px;
}

.logoBuilderSlideTemplateViewContainer::-webkit-scrollbar-track {
  background: white;
}

.logoBuilderSlideTemplateViewContainer::-webkit-scrollbar-thumb {
  background-color: #0055a4;
  border-radius: 10px;
}


.logoBuilderSlideTemplateViewItem1,
.logoBuilderSlideTemplateViewItem2,
.logoBuilderSlideTemplateViewItem3,
.logoBuilderSlideTemplateViewItem4,
.logoBuilderSlideTemplateViewItem5,
.logoBuilderSlideTemplateViewItem6,
.logoBuilderSlideTemplateViewItem7,
.logoBuilderSlideTemplateViewItem8 {
  width: 50%;
  height: 50%;
  position: relative;
}
.mockupImage1 {
  position: relative;
  width: 50%;
  height: 50%;
}

.logoBuilderSlideTemplateViewItem1 img,
.logoBuilderSlideTemplateViewItem2 img,
.logoBuilderSlideTemplateViewItem3 img,
.logoBuilderSlideTemplateViewItem4 img,
.logoBuilderSlideTemplateViewItem5 img,
.logoBuilderSlideTemplateViewItem6 img,
.logoBuilderSlideTemplateViewItem7 img,
.logoBuilderSlideTemplateViewItem8 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.logoBuilderSlideTemplateViewItem1 {
  background-color: rgb(116, 163, 183);
}
.logoBuilderSlideTemplateViewItem2 {
  background-color: rgb(211, 207, 208);
}
.logoBuilderSlideTemplateViewItem3 {
  background-color: rgb(186, 193, 185);
}
.logoBuilderSlideTemplateViewItem4 {
  background-color: rgb(221, 203, 189);
}

.logoBuilderSlideTemplateViewItem5 {
  background-color: rgb(255, 218, 5);
}
.logoBuilderSlideTemplateViewItem6 {
  background-color: rgb(212, 190, 151);
}
.logoBuilderSlideTemplateViewItem7 {
  background-color: rgb(109, 109, 109);
}
.logoBuilderSlideTemplateViewItem8 {
  background-color: rgb(153, 202, 199);
}

@media (max-width: 575.98px) {
  .logoBuilderSlideContainer {
    margin: 0 20px;
    flex-direction: column;
    gap: 20px;
  }
  .svgItemContainer {
    width: 100%;
    height: 300px;
  }
  .logoBuilderSlideTemplateViewContainer {
    width: 100%;
    height: 300px;
  }
  .tshirt {
    position: absolute;
    top: 38%;
    left: 38%;
  }
  .desktop {
    position: absolute;
    top: 46%;
    left: 42%;
  }
  .mog {
    position: absolute;
    top: 39%;
    left: 43%;
  }
  .bottle {
    position: absolute;
    top: 57%;
    left: 45%;
  }
  .laptop {
    position: absolute;
    top: 44%;
    left: 43%;
  }
  .notebook1 {
    position: absolute;
    top: 35%;
    left: 29%;
  }
  .notebook2 {
    position: absolute;
    top: 43%;
    left: 51%;
  }
  .bag1 {
    position: absolute;
    top: 57%;
    left: 31%;
  }
  .bag2 {
    position: absolute;
    top: 57%;
    left: 52%;
  }
  .paper {
    position: absolute;
    top: 43%;
    left: 38%;
  }

  .desktop svg
  {
    max-width: 78px;
  }
  .laptop svg {
    max-width: 86px; // Smaller max width for small screens
  }

  .tshirt svg {
    width: 92px;
  }
  .mog svg {
    width: 69px;
  }
  .bottle svg {
    width: 54px;
  }
  .notebook1 svg {
    width: 59px;
  }
  .notebook2 svg {
    width: 72px;
  }
  .bag1 svg {
    width: 64px;
  }
  .bag2 svg {
    width: 74px;
  }
  .paper svg {
    width: 123px;
  }
  .logoBuilderSlideTemplateViewItem1,
  .logoBuilderSlideTemplateViewItem2,
  .logoBuilderSlideTemplateViewItem3,
  .logoBuilderSlideTemplateViewItem4,
  .logoBuilderSlideTemplateViewItem5,
  .logoBuilderSlideTemplateViewItem6,
  .logoBuilderSlideTemplateViewItem7,
  .logoBuilderSlideTemplateViewItem8 {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .logoBuilderSlideContainer {
    margin: 0 30px;
  }
  .svgItemContainer {
    width: 50%;
    height: 400px;
  }
  .logoBuilderSlideTemplateViewContainer {
    width: 50%;
    height: 400px;
  }
  .logoBuilderSlideTemplateViewItem1,
  .logoBuilderSlideTemplateViewItem2,
  .logoBuilderSlideTemplateViewItem3,
  .logoBuilderSlideTemplateViewItem4,
  .logoBuilderSlideTemplateViewItem5,
  .logoBuilderSlideTemplateViewItem6,
  .logoBuilderSlideTemplateViewItem7,
  .logoBuilderSlideTemplateViewItem8 {
    width: 100%;
    height: 100%;
  }

  .tshirt {
    position: absolute;
    top: 40%;
    left: 30%;
  }
  .desktop {
    position: absolute;
    top: 46%;
    left: 35%;
  }
  .mog {
    position: absolute;
    top: 43%;
    left: 39%;
  }
  .bottle {
    position: absolute;
    top: 57%;
    left: 40%;
  }
  .laptop {
    position: absolute;
    top: 46%;
    left: 34%;
  }
  .notebook1 {
    position: absolute;
    top: 41%;
    left: 13%;
  }
  .notebook2 {
    position: absolute;
    top: 43%;
    left: 53%;
  }
  .bag1 {
    position: absolute;
    top: 57%;
    left: 16%;
  }
  .bag2 {
    position: absolute;
    top: 53%;
    left: 55%;
  }
  .paper {
    position: absolute;
    top: 48%;
    left: 32%;
  }

  .desktop svg
  {
    max-width: 85px;
  }
  .bag1 svg
  {
    max-width: 65px;
  }
  .bag2 svg {
    max-width: 60px;
  }

  .tshirt svg {
    width: 80px;
  }

  .mog svg {
    width: 63px;
  }
  .bottle svg {
    width: 59px;
  }
  .laptop svg {
    width: 97px;
  }
  .notebook1 svg {
    width: 62px;
  }
  .notebook2 svg {
    width: 71px;
  }
  .paper svg {
    width: 100px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .logoBuilderSlideContainer {
    margin: 0 40px;
  }
  .svgItemContainer {
    width: 40%;
    height: 500px;
  }
  .logoBuilderSlideTemplateViewContainer {
    width: 50%;
    height: 500px;
  }
  .logoBuilderSlideTemplateViewItem1,
  .logoBuilderSlideTemplateViewItem2,
  .logoBuilderSlideTemplateViewItem3,
  .logoBuilderSlideTemplateViewItem4,
  .logoBuilderSlideTemplateViewItem5,
  .logoBuilderSlideTemplateViewItem6,
  .logoBuilderSlideTemplateViewItem7,
  .logoBuilderSlideTemplateViewItem8 {
    width: 50%;
    height: 50%;
  }
  .tshirt {
    position: absolute;
    top: 43%;
    left: 30%;
  }
  .desktop {
    position: absolute;
    top: 47%;
    left: 35%;
  }
  .mog {
    position: absolute;
    top: 43%;
    left: 39%;
  }
  .bottle {
    position: absolute;
    top: 57%;
    left: 41%;
  }
  .laptop {
    position: absolute;
    top: 46%;
    left: 30%;
  }
  .notebook1 {
    position: absolute;
    top: 41%;
    left: 13%;
  }
  .notebook2 {
    position: absolute;
    top: 47%;
    left: 53%;
  }
  .bag1 {
    position: absolute;
    top: 57%;
    left: 15%;
  }
  .bag2 {
    position: absolute;
    top: 52%;
    left: 54%;
  }
  .paper {
    position: absolute;
    top: 49%;
    left: 32%;
  }

  .tshirt svg
  {
    width: 47px;
  }
  .desktop svg
  {
    max-width: 64px;
  }
  .mog svg {
    width: 38px;
  }
  .bottle svg {
    width: 32px;
  }
  .laptop svg {
    width: 70px;
  }
  .notebook1 svg {
    width: 38px;
  }
  .notebook2 svg
  {
    max-width: 43px;
  }
  .bag1 svg {
    width: 45px;
  }
  .bag2 svg {
    width: 42px;
  }
  .paper svg {
    max-width: 64px;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .logoBuilderSlideContainer {
    margin: 0 50px;
  }
  .svgItemContainer {
    width: 30%;
    height: 560px;
  }
  .logoBuilderSlideTemplateViewContainer {
    width: 50%;
    height: 560px;
  }
  .logoBuilderSlideTemplateViewItem1,
  .logoBuilderSlideTemplateViewItem2,
  .logoBuilderSlideTemplateViewItem3,
  .logoBuilderSlideTemplateViewItem4,
  .logoBuilderSlideTemplateViewItem5,
  .logoBuilderSlideTemplateViewItem6,
  .logoBuilderSlideTemplateViewItem7,
  .logoBuilderSlideTemplateViewItem8 {
    width: 50%;
    height: 50%;
  }
  .tshirt {
    position: absolute;
    top: 40%;
    left: 30%;
  }
  .desktop {
    position: absolute;
    top: 47%;
    left: 36%;
  }
  .mog {
    position: absolute;
    top: 39%;
    left: 39%;
  }
  .bottle {
    position: absolute;
    top: 58%;
    left: 42%;
  }
  .laptop {
    position: absolute;
    top: 43%;
    left: 31%;
  }
  .notebook1 {
    position: absolute;
    top: 37%;
    left: 15%;
  }
  .notebook2 {
    position: absolute;
    top: 44%;
    left: 53%;
  }
  .bag1 {
    position: absolute;
    top: 60%;
    left: 19%;
  }
  .bag2 {
    position: absolute;
    top: 52%;
    left: 55%;
  }
  .paper {
    position: absolute;
    top: 50%;
    left: 33%;
  }

  .desktop svg{
    max-width: 70px;
  }

  .mog svg
  {
    width: 49px;
  }

  .notebook2 svg,
  .bag1 svg,
  .bag2 svg {
    max-width: 100px;
  }

  .tshirt svg {
    width: 53px;
  }
  .bottle svg {
    width: 41px;
  }
  .laptop svg {
    width: 91px;
  }
  .notebook2 svg {
    width: 71px;
  }
  .notebook1 svg {
    width: 43px;
  }
  .bag1 svg,
  .bag2 svg {
    width: 63px;
  }
  .paper svg {
    width: 77px;
  }
}

@media (min-width: 1200px) and (max-width: 1399.98px) {
  .logoBuilderSlideContainer {
    margin: 0 60px;
  }
  .svgItemContainer {
    width: 25%;
    height: 560px;
  }
  .logoBuilderSlideTemplateViewContainer {
    width: 50%;
    height: 560px;
  }
  .logoBuilderSlideTemplateViewItem1,
  .logoBuilderSlideTemplateViewItem2,
  .logoBuilderSlideTemplateViewItem3,
  .logoBuilderSlideTemplateViewItem4,
  .logoBuilderSlideTemplateViewItem5,
  .logoBuilderSlideTemplateViewItem6,
  .logoBuilderSlideTemplateViewItem7,
  .logoBuilderSlideTemplateViewItem8 {
    width: 50%;
    height: 50%;
  }
  .tshirt {
    position: absolute;
    top: 40%;
    left: 30%;
  }
  .desktop {
    position: absolute;
    top: 47%;
    left: 36%;
  }
  .mog {
    position: absolute;
    top: 39%;
    left: 39%;
  }
  .bottle {
    position: absolute;
    top: 58%;
    left: 42%;
  }
  .laptop {
    position: absolute;
    top: 43%;
    left: 31%;
  }
  .notebook1 {
    position: absolute;
    top: 37%;
    left: 15%;
  }
  .notebook2 {
    position: absolute;
    top: 44%;
    left: 53%;
  }
  .bag1 {
    position: absolute;
    top: 60%;
    left: 19%;
  }
  .bag2 {
    position: absolute;
    top: 52%;
    left: 55%;
  }
  .paper {
    position: absolute;
    top: 50%;
    left: 33%;
  }
  .desktop svg{
    max-width: 70px;
  }

  .mog svg
  {
    width: 49px;
  }

  .notebook2 svg,
  .bag1 svg,
  .bag2 svg {
    max-width: 100px;
  }

  .tshirt svg {
    width: 53px;
  }
  .bottle svg {
    width: 41px;
  }
  .laptop svg {
    width: 91px;
  }
  .notebook2 svg {
    width: 71px;
  }
  .notebook1 svg {
    width: 43px;
  }
  .bag1 svg,
  .bag2 svg {
    width: 63px;
  }
  .paper svg {
    width: 77px;
  }
} 
@media (min-width: 1400px) {
  .logoBuilderSlideContainer {
    margin: 0 60px;
  }
  .svgItemContainer {
    width: 25%;
    height: 560px;
  }
  .logoBuilderSlideTemplateViewContainer {
    width: 50%;
    height: 560px;
  }
  .logoBuilderSlideTemplateViewItem1,
  .logoBuilderSlideTemplateViewItem2,
  .logoBuilderSlideTemplateViewItem3,
  .logoBuilderSlideTemplateViewItem4,
  .logoBuilderSlideTemplateViewItem5,
  .logoBuilderSlideTemplateViewItem6,
  .logoBuilderSlideTemplateViewItem7,
  .logoBuilderSlideTemplateViewItem8 {
    width: 50%;
    height: 50%;
  }
  .tshirt {
    position: absolute;
    top: 36%;
    left: 37%;
  }
  .desktop {
    position: absolute;
    top: 47%;
    left: 38%;
  }
  .mog {
    position: absolute;
    top: 39%;
    left: 44%;
  }
  .bottle {
    position: absolute;
    top: 58%;
    left: 45%;
  }
  .laptop {
    position: absolute;
    top: 43%;
    left: 40%;
  }
  .notebook1 {
    position: absolute;
    top: 36%;
    left: 28%;
  }
  .notebook2 {
    position: absolute;
    top: 44%;
    left: 53%;
  }
  .bag1 {
    position: absolute;
    top: 60%;
    left: 29%;
  }
  .bag2 {
    position: absolute;
    top: 52%;
    left: 54%;
  }
  .paper {
    position: absolute;
    top: 50%;
    left: 38%;
  }

  .desktop svg{
    max-width: 102px;
  }

  .mog svg
  {
    width: 49px;
  }

  .notebook2 svg,
  .bag1 svg,
  .bag2 svg {
    max-width: 100px;
  }

  .tshirt svg {
    width: 55px;
  }
  .bottle svg {
    width: 47px;
  }
  .laptop svg {
    width: 98px;
  }
  .notebook2 svg {
    width: 62px;
  }
  .notebook1 svg {
    width: 52px;
  }
  .bag1 svg,
  .bag2 svg {
    width: 63px;
  }
  .paper svg {
    width: 96px;
  }
}
