.logo-generator-base.inspiration {
  padding-bottom: 0;

  .container {
    max-width: 960px;
  }

  .logo-inspiration-container {
    max-width: 960px;
    width: 960px;
    padding-top: 30px;

    .logo {
      height: 230px;
      width: 300px;
      box-shadow: $logoSelectionBoxShadow;
      border-radius: 2px;
      margin-right: 30px;
      margin-bottom: 30px;
      transition: transform 0.25s linear;
      cursor: pointer;
      position: relative;

      .concept {
        transition: $transition;
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        & svg {
          width: 100%;
          height: auto;
        }
      }

      .get-logo-files {
        position: absolute;
        height: 60px;
        width: 100%;
        display: none;
        transition: $transition;
        bottom: 0;
        background: $superLightGrey;
        text-align: center;
      }

      &:hover {
        transform: translateY(-15px);
      }

      &:hover .get-logo-files {
        display: block;
      }

      &.selected {
        box-shadow: $colorPaletteShadow;

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border-radius: 3px;
          border: 3px solid $logomatorOrange;
        }

        &:hover {
          transform: translateY(0);
        }

        img {
          width: 100%;
          border-radius: 3px;
        }
      }

      img {
        max-height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }
    }

    div:nth-child(3n) .logo {
      margin-right: 0;
    }
  }
}
