body {
  // Globals fonts
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span,
  input,
  label {
    font-family: "Proxima Nova", sans-serif;
    font-weight: 300;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 18px;
  }

  .logo-generator-base {
    background: $lightGrey;

    .container {
      max-width: 630px;
      padding-top: 100px;
    }
  }

  // Buttons
  .logo-generator-btn {
    height: 40px;
    width: 106px;
    background: $logomatorOrange;
    border: 1px solid $logomatorOrange;
    box-shadow: $logomatorBtnShadow;
    border-radius: 2px;
    font-family: ProximaNova-Semibold, sans-serif;
    font-size: 16px;
    color: white;
    transition: $transition;

    &.generate-logos {
      width: 178px;
    }

    &.generate-more-logos {
      width: 195px;
      float: none !important;
      padding: 0;
      transition: none;
    }

    &.loading {
      width: 215px;
      background-image: url("../../images/generating.gif");
      background-repeat: no-repeat;
      background-size: 22px;
      background-position: 180px 8px;
      padding-right: 30px;

      &:hover {
        // Prevent logo-generator-btn hover from applying background
        background-image: url("../../images/generating.gif");
        background-repeat: no-repeat;
        background-size: 22px;
        background-position: 180px 8px;
      }
    }

    &.btn-disabled {
      opacity: 0.3;
      pointer-events: none;
      cursor: default;
    }

    &:hover {
      background: $darkOrange;
    }
  }

  .back-btn {
    height: 40px;
    width: 100px;
    background: transparent url("../../images/icons/back.png") no-repeat;
    background-size: 14px 12px;
    background-position: 20px 12px;
    padding-left: 29px;
    border: 1px solid $border;
    border-radius: 2px;
    font-family: ProximaNova-Semibold;
    font-size: 16px;
    color: $darkBlack;
    transition: $transition;

    &:hover {
      border: 1px solid $selectBorder;
    }
  }

  // Inputs
  input {
    font-size: 18px;
    color: $black;
    padding-bottom: 3px;
  }

  .logo-generator-textarea {
    height: 120px;
    width: 600px;
    background: white;
    border: 1px solid $selectBorder;
    border-radius: 2px;
    font-family: "Proxima Nova", sans-serif;
    font-size: 18px;
    color: $darkBlack;
    padding: 20px 20px 34px 20px;

    &:focus {
      outline: none;
      border: 1px solid $logomatorOrange;
    }
  }

  .show {
    display: block;
  }

  .no-show {
    display: none;
  }

  nav {
    position: fixed;
    height: 70px;
    width: 100%;
    padding: 15px;
    border: 1px solid #ebeff0;
    background: white;
    box-shadow: $navBoxShadow;
    z-index: 99999;

    img.logo {
      height: auto;
      width: 150px;
    }

    .right {
      display: inline-block;
      float: right;

      button.start-over {
        height: 40px;
        width: 137px;
        padding-left: 29px;
        background: transparent url("../../images/icons/start-over.png") no-repeat;
        background-size: 13px 14px;
        background-position: 20px 11px;
        border: 1px solid $border;
        font-size: 16px;
        font-family: "Proxima Nova Semi Bold";
        color: $black;
        border-radius: 2px;
        transition: $transition;

        &:hover {
          border: 1px solid $selectBorder;
        }
      }
    }
  }

  .chat-container {
    vertical-align: top;

    .chat-bubble-pic {
      display: inline-block;
      margin-right: 10px;
      vertical-align: top;

      img {
        height: 50px;
        width: 50px;
      }
    }

    .chat-bubble-message {
      display: inline-block;
      border-radius: 10px;
      max-width: 540px;
      width: auto;
      padding: 20px;
      background: $veryLightGrey;
      word-wrap: break-word;
    }
  }

  .loading-container {
    display: none;
    width: 100%;
    height: 100%;
    background: $lightGrey;
    position: fixed;
    z-index: 999999;

    &.show {
      display: block;
    }

    .loading {
      height: 700px;
      width: 700px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: auto;

      .chat-container {
        max-height: 472px;
        max-width: 630px;

        h1,
        h2 {
          margin: 0;
          color: $black;
          font-size: 16px;
        }

        span {
          font-size: 16px;
          font-family: $font-promixa-nova-semibold, sans-serif;
        }
      }

      img.loading-gif {
        display: block;
        width: 145px;
        height: 109px;
      }
    }
  }
}

// Input text area placeholders
::-webkit-input-placeholder {
  /* Chrome */
  color: $selectBorder;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: $selectBorder;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: $selectBorder;
  opacity: 1;
}
:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: $selectBorder;
  opacity: 1;
}
