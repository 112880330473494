// Colors
$black: #2E2E2E;
$cursorColor: #008CFF;
$darkBlack: #19191A;
$darkOrange: #E04A08;
$darkGrey: #67676F;
$grey: #80858E;
$lightGrey: #FDFDFD;
$very-dark-grey: #81868F;
$veryLightGrey: #F3F3F3;
$superLightGrey: #FBFBFB;


// Progress bar
$progress-bar-bg: #E3E4E4;

// Brands
$logomatorOrange: #F5530C;
$brand-primary: #3097D1;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// Borders
$border: #DFE3E6;
$input-border: #B3B3B3;
$selectBorder: #B0B5B8;
$progressBorder: #EBEFF0;

// Shadows
$logoSelectionBoxShadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
$navBoxShadow: 0 4px 0 hsla(240,5%,96%, .5);
$colorPaletteShadow: 0 4px 20px 0 rgba(0,0,0,0.15);
$logomatorBtnShadow: 0 1px 3px 0 rgba(181,181,181,0.15);
$progressBarShadow: 0 -4px 0 hsla(240,5%,96%, .5);

// Typography
$font-promixa-nova-reg: 'Promixa Nova';
$font-promixa-nova-bold: 'Promixa Bold';
$font-promixa-nova-semibold: 'Proxima Nova Semi Bold';
$font-promixa-nova-light: 'Promixa Light';
$fontMontserrat: 'Montserrat';
$fontMontserratSemiBold: 'Montserrat Semibold';
$fontMontserratBold: 'Montserrat Bold';


// Transition
$transition: .25s all linear;

// Color palette selection

// Blues
$lightest-blue: #B1D9F9;
$very-light-blue: #A0D0F8;
$lightBlue: #84C4F5;

$light-dark-blue: #6DB7F2;
$blue: #1C87E1;
$darker-blue: #146FB8;

$darkBlue: #0D5DA0;
$very-dark-blue: #0A5693;
$darkestBlue: #073E75;

// Aquas
$lightest-aqua: #BDE5EC;
$very-light-aqua: #B0E2E8;
$light-aqua: #9AD9E1;

$light-dark-aqua: #86D0DA;
$aqua: #4BB8C3;
$darker-aqua: #3A8D9C;

$dark-aqua: #337683;
$very-dark-aqua: #2B6A79;
$darkest-aqua: #1D5463;

// Greens
$lightest-green: #B8D0B9;
$very-light-green: #A1C2A2;
$light-green: #8AB28B;

$light-dark-green: #71A373;
$green: #5A935C;
$darker-green: #438444;

$dark-green: #2B742C;
$very-dark-green: #126513;
$darkest-green: #005502;

// Purples
$lightest-purple: #E3D0EC;
$very-light-purple: #D5B9E3;
$light-purple: #C7A2D9;

$light-dark-purple: #B98BD0;
$purple: #9D5CBE;
$darker-purple: #8F45B4;

$dark-purple: #812EAB;
$very-dark-purple: #7317A2;
$darkest-purple: #660099;

// Pinks
$lightest-pink: #F1C3DF;
$very-light-pink: #ECAFD6;
$light-pink: #E79BCB;

$light-dark-pink: #E487C1;
$pink: #DF73B6;
$darker-pink: #DA5EAC;

$dark-pink: #D54AA1;
$very-dark-pink: #D03597;
$darkest-pink: #CC1F8D;

// Reds
$lightest-red: #F2B9B8;
$very-light-red: #EEA2A1;
$light-red: #E98A89;

$light-dark-red: #E57271;
$red: #E05B59;
$darker-red: #DB4440;

$dark-red: #D72C26;
$very-dark-red: #D21204;
$darkest-red: #CE0000;

// Oranges
$lightest-orange: #FFD5B7;
$very-light-orange: #FFC79F;
$light-orange: #FFB987;

$light-dark-orange: #FFAB6E;
$orange: #FF9D54;
$darker-orange: #FF8F3A;

$dark-orange: #FF811A;
$very-dark-orange: #FF7300;
$darkest-orange: #FF6600;

// Yellows
$lightest-yellow: #FFF2B6;
$very-light-yellow: #FFED9E;
$light-yellow: #FFE885;

$light-dark-yellow: #FFE46A;
$yellow: #FFDF4F;
$darker-yellow: #FFDA30;

$dark-yellow: #FFD600;
$very-dark-yellow: #FFD100;
$darkest-yellow: #FFCD00;

// Browns
$lightest-brown: #B7B1AB;
$very-light-brown: #A59D96;
$light-brown: #938A80;

$light-dark-brown: #81766B;
$brown: #6F6356;
$darker-brown: #5D4F41;

$dark-brown: #4B3C2C;
$very-dark-brown: #3A2917;
$darkest-brown: #352615;

// Light neutrals
$lightest-light-neutral: #F9F9F9;
$very-light-light-neutral: #F3F3F4;
$light-light-neutral: #E4E6E7;

$light-dark-light-neutral: #D3D5D9;
$light-neutral: #C4C6CC;
$darker-light-neutral: #B4B7BE;

$dark-light-neutral: #A3A7AE;
$very-dark-light-neutral: #93969F;
$darkest-light-neutral: #818691;

// Dark neutrals
$lightest-dark-neutral: #93969F;
$very-light-dark-neutral: #818691;
$light-dark-neutral: #737883;

$light-dark-dark-neutral: #646973;
$dark-neutral: #565964;
$darker-dark-neutral: #484C53;

$dark-dark-neutral: #404247;
$very-dark-dark-neutral: #292B2F;
$darkest-dark-neutral: #1A191C;

// End of color palette